<div *ngIf="loading && !showCalculating && !showCallToAction">
    <div class="loader__container mt-0">
        <div class="loader"></div>
    </div>
    <div class="center-div">{{ loadingMessage }}</div>
</div>
<div class="calculating-loader-container" *ngIf="showCalculating">
    <ng-container *ngIf="showTemplateReSpreadCalculatingView; else defaultCalculatingView">
        <app-template-re-spread-loading-view></app-template-re-spread-loading-view>
    </ng-container>
    <ng-template #defaultCalculatingView>
        <app-calculating
            [finish]="finishedCalculating"
            (finishedLoading)="hideCalculatingLoader()"
        ></app-calculating>
    </ng-template>
</div>
<div *ngIf="didErrorOccur">
    <ng-container *ngIf="showTemplateReSpreadErrorState; else defaultErrorState">
        <div class="empty-state-container re-spread-error-empty-state-container">
            <app-table-empty-state
                [emptyStateIcon]="reSpreadErrorIcon"
                [headerText]="reSpreadErrorHeaderText"
                [helpText]="reSpreadErrorHelpText"
            ></app-table-empty-state>
        </div>
    </ng-container>
    <ng-template #defaultErrorState>
        <div class="loader__container">
            <div class="col-6 offset-3">
                <i class="fas fa-exclamation-triangle icon"></i>
                <h3>An error occurred and our team has been notified.</h3>
                <h3>Please reach out to support via our in app chat if you have further questions.</h3>
            </div>
        </div>
    </ng-template>
</div>
<div *ngIf="showCallToAction && loading">
    <div class="loader__container">
        <div class="col-6 offset-3">
            <i class="fas fa-mug-hot icon"></i>
            <h4>This is embarrassing! Calculating these financials seems to be taking longer than it should. Go grab a
                coffee - your data should be available soon! If you think there's an issue, please reach out to our
                support team via our in app chat</h4>
        </div>
    </div>
</div>
<div *ngIf="!loading && this.pageViewMode===COMPANY_PAGE_VIEW_MODE.NO_ACCESS">
    <borrower-alert [alertText]="noAccessErrorText"></borrower-alert>
</div>
<div *ngIf="!loading && !didErrorOccur && canViewFinancials">
    <ng-container *ngIf="shouldShowEmptyState; else NoFinancials">
        <div class="financials-analysis">
            <div class="inner-content financials-toolbar">
                <div class="d-flex flex-wrap toolbar justify-content-between align-items-center">
                    <div class="template-name-label-container">
                        <h5 class="mb-0">
                            {{ formatDataViewName(activeDataView.title) }}
                        </h5>
                    </div>
                    <div>
                        <div class="buttons-container">
                            <div id="showHiddenButton" class="btn-group clickable-text"
                                 [class.disabled]="showHiddenDisabled" [class.enabled]="!showHiddenDisabled"
                                 role="toolbar"
                                 (click)="toggleHidden()">
                                <label *ngIf="showHidden" class="hideHidden"><i class="fas fa-eye-slash pt-1"></i>Hide
                                    Hidden</label>
                                <label *ngIf="!showHidden" class="showHidden"><i class="fas fa-eye pt-1"></i>Show Hidden</label>
                            </div>
                            <div class="btn-group clickable-text showAll" role="toolbar"
                                 [class.disabled]="removeHiddenDisabled" [class.enabled]="!removeHiddenDisabled"
                                 (click)="removeShowAll()">
                                <label class="showAll"><i class="fas fa-trash pt-1"></i>
                                    Show All (clears hidden)</label>
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="clearUndoDisabled" (click)="undoLastHidden()">
                                <i class="fas fa-undo pt-1"></i> Undo Last Show/Hide
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="roundToThousandsDisabled"
                                 (click)="toggleRoundToThousands()">
                              <span class="item-checked" *ngIf="roundToThousands">
                                  <i class="item-checked far fa-check-square"></i><i class="fas">000</i>000's
                              </span>
                                <span *ngIf="!roundToThousands">
                                  <i class="far fa-square"></i><i class="fas strike-through">000</i>000's
                              </span>
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="dollarSignDisabled" (click)="toggleDollarSign()">
                              <span class="item-checked" *ngIf="dollarSign">
                                  <i class="item-checked far fa-check-square"></i><i class="fas fa-dollar-sign"></i>Currency
                              </span>
                                <span *ngIf="!dollarSign">
                                  <i class="far fa-square"></i><i class="fas fa-dollar-sign"></i>Currency
                              </span>
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="numbersDisabled" (click)="toggleNumbers()">
                              <span class="item-checked" *ngIf="numbers">
                                  <i class="item-checked far fa-check-square"></i><i class="fas fa-list-ol"></i>Numbers
                              </span>
                                <span *ngIf="!numbers">
                                  <i class="far fa-square"></i><i class="fas fa-list-ol"></i>Numbers
                              </span>
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="commonSizeDisabled" (click)="toggleCommonSize()">
                              <span class="item-checked" *ngIf="commonSize">
                                  <i class="item-checked far fa-check-square"></i><i class="fas fa-percent"></i>Common
                                  Size
                              </span>
                                <span *ngIf="!commonSize">
                                  <i class="far fa-square"></i><i class="fas fa-percent"></i>Common Size
                              </span>
                            </div>
                            <div class="btn-group clickable-text" role="toolbar"
                                 [class.disabled]="trendDisabled" (click)="toggleTrend()">
                              <span class="item-checked" *ngIf="trend">
                                  <i class="item-checked far fa-check-square"></i><i class="fas fa-chart-line"></i>Trend
                              </span>
                                <span *ngIf="!trend">
                                  <i class="far fa-square"></i><i class="fas fa-chart-line"></i>Trend
                              </span>
                            </div>
                          <div class="btn-group clickable-text" role="toolbar" *ngIf="this.activeDataView.templated"
                              [class.disabled]="sourceItemsDisabled" (click)="toggleSourceItems()">
                              <span class="item-checked" *ngIf="sourceItems">
                                  <i class="item-checked far fa-check-square"></i><i class="fas fa-list-ul"></i>Source Items
                              </span>
                              <span *ngIf="!sourceItems">
                                  <i class="far fa-square"></i><i class="fas fa-list-ul"></i>Source Items
                              </span>
                          </div>
                            <app-popover-menu
                                [menuItems]="actionMenuItems"
                                buttonText="Actions"
                            ></app-popover-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="financials-container">
                <div class="row">
                    <div class="splitscreen-wrapper col-6 col-sm-6 col-md-6 col-lg-6" *ngIf="isFootnotesViewOn">
                        <financial-summary-split-screen
                            [activePage]="activeFootnotePage"
                            [readOnlyCropper]="cropper"
                            [docFileId]="docFileId"
                            (closeSplitscreen)="closeFootnotes()"
                        ></financial-summary-split-screen>
                    </div>
                    <div [ngClass]="{
                      'table_container': 'true',
                      'footnotes-open': isFootnotesViewOn,
                      'col-6': isFootnotesViewOn,
                      'col-12': !isFootnotesViewOn,
                      'statement-builder-flyout-open': isStatementBuilderFlyoutOpen,
                      'col-7': isStatementBuilderFlyoutOpen,
                      'col-12': !isFootnotesViewOn && !isStatementBuilderFlyoutOpen
                  }">
                        <div class="scroll financials-table" [ngClass]="
                          {
                              'extra-space-graph': isGraphShown && !showInsights,
                              'extra-space-insights': !isGraphShown && showInsights,
                              '': !isGraphShown && !showInsights,
                              'parent-financials': !embeddedMode,
                              'embedded-financials': embeddedMode
                          }"
                        >
                            <div *ngIf="!activeDataView || !activeDataView.rows.length">
                                <div class="empty-state-container">
                                    <app-table-empty-state
                                        [emptyStateIcon]="noSourceDataExistsIcon"
                                        [headerText]="noSourceDataExistsHeaderText"
                                        [helpText]="noSourceDataExistsHelpText"
                                    ></app-table-empty-state>
                                </div>
                            </div>
                            <div *ngIf="activeDataView && activeDataView.rows.length">
                                <app-financials-table
                                    [roundToThousands]="roundToThousands"
                                    [rows]="activeDataView.rows"
                                    [hiddenRowIndices]="hiddenRows"
                                    [columns]="activeDataView.columns"
                                    [hiddenColumnIndices]="hiddenColumns"
                                    [showNumbers]="numbers"
                                    [showCommonSize]="commonSize"
                                    [showTrend]="trend"
                                    [showSourceItems]="sourceItems"
                                  [documentMenuItems]="documentMenuItems"
                                    [allowEditCells]="activeDataView.raw"
                                    [showHidden]="showHidden"
                                    [dollarSign]="dollarSign"
                                    [showSpreadingInContextMenu]="showSpreadingInContextMenu"
                                    [statementType]="formatDataViewName(activeDataView.title)"
                                    [companyId]="company.id"
                                    [templated]="activeDataView.templated"
                                    [projectionScenarioTypeLabelOverride]="projectionScenarioTypeLabelOverride"
                                    (rowToggled)="rowToggled($event)"
                                    (toggleRowVisibility)="toggleRowVisibility($event)"
                                    (columnHidden)="columnHidden($event)"
                                    (openFootnotes)="openFootnotesOrSourceBox($event)"
                                    (openInsights)="openInsights($event)"
                                    (beginDeleteColumn)="beginDeleteColumn($event)"
                                    (beginEditColumn)="beginEditColumn($event)"
                                    [viewOnlyMode]="pageViewMode===COMPANY_PAGE_VIEW_MODE.VIEW_ONLY"
                                    [embeddedMode]="embeddedMode"
                                    [commonSizeDecimalPrecision]="commonSizeDecimalPrecision"
                                >
                                </app-financials-table>
                            </div>
                        </div>
                        <div *ngIf="isGraphShown" class='chart-container'>
                            <div class='card mb-3'>
                                <div class='chart-header card-header'>
                                    <ng-container *ngIf="graphItems.length > 0">
                                        {{ graphItemLabels }}
                                    </ng-container>
                                    <div class="chart-close" (click)="closeGraph()">
                                        <i class="fas fa-times clickable"></i>
                                    </div>
                                </div>
                                <div class="card-body chart-body">
                                    <app-graph [graphItems]="graphItems" [height]="300" [width]="1000"
                                               [yAxisLabel]="graphYAxisLabel">
                                    </app-graph>
                                </div>
                            </div>
                        </div>
                        <app-cell-information></app-cell-information>
                        <div *ngIf="showInsights" class='insights-container'>
                            <div class='card mb-3'>
                                <div class='chart-header card-header insight-header'>
                                    {{ currentInsightLabel }}
                                    <div style="display: inline; font-weight: normal; margin-left: 10px;">
                                        {{ insightsPayload.statementDate }}
                                    </div>
                                    <div class="chart-close" (click)="closeInsights()">
                                        <i class="fas fa-times clickable"></i>
                                    </div>
                                    <div class="insights-comparison-label"
                                         *ngIf="insights[0].components[0].prevValue !== null">
                                        Comparison to previous period <span
                                        class="insights-prior-period-metadata">({{ insights[0].priorPeriodMetadataString }}
                                        )</span>
                                    </div>
                                    <div class="insights-comparison-label"
                                         *ngIf="insights[0].components[0].prevValue === null">
                                        No period available for comparison
                                    </div>
                                </div>
                                <div class="insights-inner-container" [ngClass]="{
                                'embedded-mode-insights': embeddedMode,
                                'standalone-mode-insights': !embeddedMode
                              }">
                                    <div *ngFor="let insight of insights; let insightIdx = index">
                                        <app-insights
                                            [insights]="insight"
                                            (detailSelected)="openInsightsDetail($event, insightIdx)"
                                            (detailClosed)="closeInsightsDetail($event, insightIdx)"
                                            (footnoteSelected)="openFootnotesOrSourceBox($event)"
                                            (sourceBoxSelected)="openFootnotesOrSourceBox($event)"
                                        ></app-insights>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isStatementBuilderFlyoutOpen" class="col-5 statement-builder-flyout-container">
                        <div class="statement-builder-flyout">
                            <app-statement-builder-flyout
                                [company]="company"
                                [embeddedMode]="embeddedMode"
                                [columns]="activeDataView.columns"
                                [rows]="activeDataView.rows"
                                [spreadingTemplateItems]="spreadingTemplateItems"
                                [statementBuilderEditMode]="statementBuilderEditMode"
                                [frameToBeEditedId]="frameToBeEditedId"
                                (closeStatementBuilderFlyout)="closeStatementBuilderFlyout($event)"
                            ></app-statement-builder-flyout>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar-container">
                <nav class="navbar navbar-light navbar-expand navbar-financials p-0">
                    <ul class="navbar-nav financial-tabs">
                        <li *ngFor="let view of dataViewsList" [ngClass]="{'nav-active': dataViewId === view.id, 'active': dataViewId === view.id }"
                            class="nav-item">
                            <a class="nav-link" [routerLink]="dataViewRouterLink(view.id)">
                                {{ formatDataViewName(view.title) }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #NoFinancials>
    <div class="empty-state-container" *ngIf="!hasDocuments">
        <div>
            <app-table-empty-state
                [emptyStateIcon]="noFinancialsExistIcon"
                [headerText]="noFinancialsExistHeaderText"
                [helpText]="noFinancialsExistHelpText"
            ></app-table-empty-state>
        </div>
        <div class="upload-button-container">
            <button *ngIf="showEmptyStateUploadButton" (click)="openUploadDocumentModal()"
                    class="btn btn-primary" type="button">
                <i class="fa-solid fa-arrow-up-from-bracket"></i>
                <span>Upload Documents</span>
            </button>
        </div>
    </div>

    <div class="empty-state-container" *ngIf="hasDocuments">
        <div>
            <app-table-empty-state
                [emptyStateIcon]="noFinancialsExistIcon"
                [headerText]="noFinancialsCompleteHeaderText"
                [helpText]="noFinancialsCompleteHelpText"
            ></app-table-empty-state>
        </div>
        <div class="upload-button-container hasDoc">
            <button *ngIf="showEmptyStateUploadButton"
                    [routerLink]="['/companies', company.uuid, 'data']"
                    class="btn btn-primary" type="button">
                <span>View Uploaded Documents</span>
                <i class="fa-solid fa-arrow-right hasDoc"></i>


            </button>
        </div>
    </div>


</ng-template>
