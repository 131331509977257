import { DataFrame, DataFrameValue } from './dataframe';
import { DataFrameFormula } from './dataframe-formula';
import { LineItemObject } from './line-item';
import { ContextMenuDocument } from '../components/main/borrower/details/financials/analysis/view-templated/table/cell/context-menu';
import { NON_SPREADABLE_COLUMN_PREP_TYPES } from '@utils/constants';

export class DataViewCell {
  formula: DataFrameFormula;
  calculatedValue: DataFrameValue;
  trendValue: DataFrameValue;
  commonSizedValue: DataFrameValue;

  // Used by the front-end
  selected = false;
  formattedValue: string;
  contextMenuOpen: boolean;
  isHidden = false;
  ref?: string;
  documentOptions?: Array<ContextMenuDocument>;

  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.formula) {
      this.formula = new DataFrameFormula().deserialize(this.formula);
    }
    if (this.calculatedValue) {
      this.calculatedValue = new DataFrameValue().deserialize(this.calculatedValue);
    }
    if (this.trendValue) {
      this.trendValue = new DataFrameValue().deserialize(this.trendValue);
    }
    if (this.commonSizedValue) {
      this.commonSizedValue = new DataFrameValue().deserialize(this.commonSizedValue);
    }
    return this;
  }
}

export class DataViewCellFormattingOptions {
  textFormat: string;
  replaceRef: string;
  replaceDivzero: string;
}

export class DataViewRowFormattingOptions {
  header: boolean;
  bold: boolean;
  valueSource: string;
  numDecimals: number;
  isHiddenDueToZeros?: boolean;
  isParent?: boolean;
}

export class DataViewColumn {
  cells: Array<DataViewCell>;
  header: string;
  cellFormat: DataViewCellFormattingOptions;
  dataFrameId: number;
  showInGraph: Boolean;
  fileIds: Array<number>;

  sourceStatementIds: Array<number>;

  currencyFormat: string;
  reportingPeriod: string;
  statementDate: string;
  preparationType: string;
  reportingInterval: string;
  scenario: string;
  monthsInInterval: number;
  documentOptions: Array<ContextMenuDocument>;
  isHidden = false;
  trendIsHidden = false;
  commonSizeIsHidden = false;
  interpolated = false;
  combinedFrameId: number;
  importedFrameId: number;
  forecastedFrameId: number;
  projectionName: string;
  periodKeyUuid?: string;
  combinedColumnStatementBuilderOriginType?: string;

  toKeyString(): string {
    const keyString = `${this.reportingPeriod}.${this.preparationType.toLowerCase()}`;
    return (!this.projectionName || this.projectionName === '') ? keyString : keyString + `.${this.projectionName}`
  }

  isDeletable(): boolean {
    // currently, we only allow deleting CombinedFrames and ImportedFrames directly
    return  NON_SPREADABLE_COLUMN_PREP_TYPES.includes(this.preparationType);
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (this.cells) {
      this.cells = this.cells.map(cell => new DataViewCell().deserialize(cell));
    }

    if (!this.cellFormat) {
      this.cellFormat = new DataViewCellFormattingOptions();
    }

    return this;
  }
}
export class DataViewRow {
  label: string;
  lineItem: LineItemObject;
  formula: DataFrameFormula;
  rowFormat: DataViewRowFormattingOptions;
  cellFormat: DataViewCellFormattingOptions;
  indentation = 0;
  ref?: string;
  rollupBehavior?: string;
  hasRollup?: boolean;

  // Drives the front end
  selected = false;
  isHidden = false;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.lineItem) {
      this.lineItem = new LineItemObject().deserialize(this.lineItem);
    }
    if (this.formula) {
      this.formula = new DataFrameFormula().deserialize(this.formula);
    }

    if (!this.rowFormat) {
      this.rowFormat = new DataViewRowFormattingOptions();
    }
    if (!this.cellFormat) {
      this.cellFormat = new DataViewCellFormattingOptions();
    }
    return this;
  }
}

export class DataView {
  id: number;
  uuid: string;
  company: number;
  generator: number;
  title: string;
  rules: any;
  frames: Array<DataFrame>;
  columns: Array<DataViewColumn>;
  rows: Array<DataViewRow>;
  options: object;
  raw = false;
  calculating: boolean;
  companyInReSpreadErrorState: boolean;
  templateRespreadInProgress: boolean;
  templated: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.columns) {
      this.columns = this.columns.map(column => new DataViewColumn().deserialize(column));
    }
    if (this.rows) {
      this.rows = this.rows.map(row => new DataViewRow().deserialize(row));
    }
    return this;
  }
}
