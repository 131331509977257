import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxPopupComponent} from "@components/shared/ngx-popups/ngx-popups/components/popup.component";

@Component({
  selector: 'app-re-spread-used-incomplete-statements-warning-modal',
  templateUrl: './re-spread-used-incomplete-statements-warning-modal.component.html',
  styleUrls: ['./re-spread-used-incomplete-statements-warning-modal.component.scss']
})
export class ReSpreadUsedIncompleteStatementsWarningModalComponent {
  @Input() popup: NgxPopupComponent; // MANDATORY
  @Output() callback = new EventEmitter<boolean>();

  constructor() {
  }

  okClicked() {
    this.callback.emit(true);
    this.popup.close();
  }

}
