<div>
    <div class="modal__header">
        <div class="modal__title">
            <i class="fa fa-list-check"></i>
            FINANCIAL SUMMARY UPDATED
        </div>
    </div>
    <div class="modal__content">
        <div class="warning-message">
            Template updates requested by your financial institution are complete. Any edits saved in the data
            categorization step have been applied to the financial summary for this borrower. Please reach out to your
            administrator if you have any questions.
        </div>
    </div>
    <div class="modal__footer">
        <button class="btn btn-primary" (click)="okClicked()">Ok</button>
    </div>
</div>
