import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import {AutocompleteOption, Categorization, Cell, CellEvent, Column, ItemClicked, Row} from './models/models';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from '../../../decorators/auto-unsubscribe';
import {NgxPopupComponent, NgxPopupService} from '../../shared/ngx-popups/ngx-popups/ngx-popups';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentFileService} from '../../../services/document-file.service';
import {SpreadingState} from './state/manager';
import {RowSelectionActionType} from './state/actions';
import {AlertService} from '../../../services/alert.service';
import {SavingPopupComponent} from '../../shared/popups/saving/saving-popup.component';
import {HttpErrorResponse} from '@angular/common/http';
import {SharedDataService} from '../../../services/shared-data.service';
import {ReviewQueueItem} from '../../../models/review-queue-item';
import {Company} from '../../../models/company';
import {UserService} from '../../../services/user.service';
import {StatementDataService} from './data/statement-data.service';
import {GoalSeekService} from '../../../services/goal-seek.service';
import {
  ACCEPTABLE_URLS, DYNAMIC_ADJUSTMENT_STATEMENT,
  IS_MAC,
  LANGUAGES,
  SCENARIO_TYPE_PROJECTION,
  SPREAD_ACTION_ADD_COLUMN,
  SPREAD_ACTION_ADJUSTMENT_CREATED,
  SPREAD_ACTION_CATEGORIZE,
  SPREAD_ACTION_CLEAR_CATEGORIZATION,
  SPREAD_ACTION_COLUMN_METADATA_CHANGED,
  SPREAD_ACTION_CUT_SELECTED,
  SPREAD_ACTION_DELETE_COLUMNS,
  SPREAD_ACTION_DELETE_ROW,
  SPREAD_ACTION_EDIT_A_CELL,
  SPREAD_ACTION_INVERT_ROWS,
  SPREAD_ACTION_PASTE_SELECTED,
  SPREAD_ACTION_ROW_LABEL_CHANGED,
  SPREAD_ACTION_TOGGLE_COLUMN,
  SPREAD_ACTION_TOGGLE_ROW,
  SPREAD_ACTION_UNCATEGORIZE_ROW,
  SUPPORTED_CURRENCIES,
  SUPPORTED_VIEW_SOURCE_FILE_TYPES,
  TAXONOMY_ID_STATEMENT_TYPE_LOOKUP,
  UNKNOWN_ERROR_TOAST_BODY,
  UNKNOWN_ERROR_TOAST_TITLE,
  UNLABELED_ROW_PREFIX,
  USER_GUIDES,
} from '../../../utils/constants';
import {Box} from './models/box';
import {ConfirmationPopupComponent} from '../../shared/popups/confirmation/confirmation-popup.component';
import {LaunchDarklyService} from '../../../services/launchdarkly.service';
import {Statement} from '../review/human-validation/models/statement';
import {StatementDataCache} from './data/cache';
import {TrackingService} from '../../../services/tracking.service';
import {BorrowerService} from '../../../services/borrower.service';
import {Logger, LoggingService} from '../../../services/logging.service';
import {SpreadingTemplateService} from '../../../services/spreading-template.service';
import {DataFrameService} from '../../../services/dataframes.service';
import {SpreadingHistoryService} from '../../../services/spreading-history.service';
import {UserGuideService} from '@services/user-guide.service';
import {StatementService} from '@services/statement.service';
import {CommentService} from '@services/shared/comment/comment.service';
import {DigitizationService} from '@services/shared/digitization/digitization.service';
import {Digitization} from './models/digitization';
import {
  PromptForProjectionNameComponent
} from '@components/main/spreading/popups/prompt-for-projection-name/prompt-for-projection-name.component';
import {
  SelectSpreadingTemplateModalComponent
} from './modals/select-spreading-template-modal/select-spreading-template-modal.component';
import {TemplateItem} from "@models/template-item";
import {CommentModalComponent} from "@components/shared/comment-modal/comment-modal.component";
import {CommentDeleteModalComponent} from "@components/shared/comment-delete-modal/comment-delete-modal.component";

declare var bootstrap: any;

@Component({
  selector: 'app-spreading',
  templateUrl: './spreading.component.html',
  styleUrls: ['./spreading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GoalSeekService]
})
@AutoUnsubscribe('subsArr$')
export class SpreadingComponent implements OnInit, OnDestroy, AfterViewInit {
  subsArr$: Subscription[] = [];
  state: SpreadingState;
  isDASStatement = false;
  dasRefSnapshot: any;
  stdFrameRefSnapshot: any;
  spreadContainsDASStatement = false;
  loading = true;
  reSpreadInProgress = false;
  alreadyCompleted = false;
  viewMode: 'categorize' | 'preview' | 'alerts' = 'categorize';
  forceIntegrationSync = false;
  reviewQueueItem: ReviewQueueItem = null;
  fileId = -1;
  statementId = -1;
  statementUuid = null;
  relatedStatements: Array<Statement>;
  company: Company = null;
  isError = false;
  currentSum = 0.0;
  viewInNativeLanguage = true;
  swapTranslationLabel = '';

  hasTemplate = false;
  availableTemplates = [];
  selectedTemplate = null;
  isTemplateChooserDisabled = true;
  templateName = '';
  companyObject: Company;

  editingRowLabelIndex = -1;
  editingColumnHeaderIndex = -1;
  editingColumnHeader = 'statementDate';

  contextMenuMode: 'row' | 'sourceCell' | 'column' | 'dasCell' | '' = '';
  contextMenuRow: Row = null;
  contextMenuColumn: Column = null;
  contextMenuCell: Cell = null;
  contextMenuObjectIndex = -1;

  documentSidebarIsOpen: boolean = false;

  viewSourcePage: number;
  viewSourceBox: Box;

  existingColumns = [];

  enableAccessToDoc = false;

  madeChanges = false;

  canCategorize = true;
  logger: Logger;

  originalDocumentFileUrl: string;

  contextMenuStyle = {
    'display': 'none',
    'top': '0px',
    'left': '0px',
  };

  dataCache: StatementDataCache;

  enableGoalSeek = false;
  goalSeekLineItem = -1;
  visibleSuggestionsOriginId = null;

  shouldHideTemplate = false;
  scrollTop = 0;
  scrollLeft = 0;

  UNLABELED_ROW_PREFIX = UNLABELED_ROW_PREFIX;

  mergeTableInsertAtLineItemId = -1;

  cellForComment: Cell;
  rowForComment: Row;

  scenarioTypeProjection = SCENARIO_TYPE_PROJECTION;
  showSourceEnabledInContextMenu = false;

  noMoreUndos = true;
  noMoreRedos = true;
  undoButtonLoading = false;
  redoButtonLoading = false;

  supportedCurrencies = SUPPORTED_CURRENCIES;
  currency: string;
  receivedValue: string = ""
  justPressedEscape = false;

  hotkeys: Array<Hotkey> = [
    new Hotkey('shift+down', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      // If they were just shift-upping, shift-down should mean deselect the top one, rather than include the bottom one.
      if (this.state.shiftDirection === 'up') {
        this.state.rowSelectionAction(RowSelectionActionType.DESELECT_FIRST);
      } else {
        this.state.rowSelectionAction(RowSelectionActionType.INCLUDE_NEXT);
      }
      this.recalculateSelectedCellSum(false, 'shift down');
      this.cellEditing({
        cellIdx: -1,
        row: null,
      });
      this._crd.detectChanges();
      return true;
    }),

    new Hotkey('shift+up', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      // If they were just shift-downing, shift-up should mean deselect the bottom one, rather than include the top one.
      if (this.state.shiftDirection === 'down') {
        this.state.rowSelectionAction(RowSelectionActionType.DESELECT_LAST);
      } else {
        this.state.rowSelectionAction(RowSelectionActionType.INCLUDE_PREVIOUS);
      }
      this.recalculateSelectedCellSum(false, 'shift up');
      this._crd.detectChanges();
      return true;
    }),

    new Hotkey('left', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      this.state.columnSelectionAction(RowSelectionActionType.SELECT_PREVIOUS);
      this.recalculateSelectedCellSum(false, 'move key left');
      this._crd.detectChanges();
      return true;
    }),

    new Hotkey('right', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      this.state.columnSelectionAction(RowSelectionActionType.SELECT_NEXT);
      this.recalculateSelectedCellSum(false, 'shift right');
      this._crd.detectChanges();
      return true;
    }),

    // Categorize as a component
    new Hotkey('c', (evt: KeyboardEvent, combo: string) => {
      evt.preventDefault();
      this.categorizeSelectedRows(false);
      return true;
    }),

    // Categorize as a validation
    new Hotkey('v', (evt: KeyboardEvent, combo: string) => {
      evt.preventDefault();
      this.categorizeSelectedRows(true);
      return true;
    }),

    // Select next
    new Hotkey('enter', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      this.state.rowSelectionAction(RowSelectionActionType.SELECT_NEXT);
      this.recalculateSelectedCellSum(false, 'select next');
      this._crd.detectChanges();
      return true;
    }),

    // Select previous
    new Hotkey('shift+enter', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      this.state.rowSelectionAction(RowSelectionActionType.SELECT_PREVIOUS);
      this.recalculateSelectedCellSum(false, 'select previous');
      this._crd.detectChanges();
      return true;
    }),

    // Deselect all
    new Hotkey('escape', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      this.state.rowSelectionAction(RowSelectionActionType.DESELECT_ALL);
      this.stopInProgressCategorizations();
      this.recalculateSelectedCellSum(false, 'deselect all');
      this._crd.detectChanges();
      this.cellEditing({
        cellIdx: -1,
        row: null,
      });
      return true;
    }),

    new Hotkey('alt+i', (evt: KeyboardEvent, combo: string) => {
      this.invertSelectedRows();
      return true;
    }),

    // Uncategorize the selected rows
    new Hotkey('u', (evt: KeyboardEvent, combo: string) => {
      this.uncategorizeSelectedRows();
      return true;
    }),

    // Add adjustment for selected row
    new Hotkey('alt+a', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      const selectedRowIdx = this.state.getFirstSelectedRowIndex();
      if (selectedRowIdx < 0) {
        return true;
      }
      this.addAdjustmentRow(selectedRowIdx, this.state.sourceRows[selectedRowIdx]);
      return true;
    }),

    // Edit specific cell
    new Hotkey('f2', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      const selectedRowIdx = this.state.getFirstSelectedRowIndex();
      const selectedColumnIdx = this.state.getLastSelectedItemIndex('column');
      if (selectedColumnIdx < 0 || selectedRowIdx < 0 ) {
        return true;
      }
      this.editCell(this.state.sourceRows[selectedRowIdx], selectedColumnIdx);
      this.receivedValue = this.state.sourceRows[selectedRowIdx].cells[selectedColumnIdx].rawText
      return true;
    }),

    // Insert row below if row selected
    // Insert column right if no row selected
    new Hotkey(['ctrl+plus', 'ctrl+='], (evt: KeyboardEvent, combo: string) => {
      const selectedRowIdx = this.state.getLastSelectedItemIndex('row');
      if (selectedRowIdx < 0) {
        const selectedColumnIdx = this.state.getLastSelectedItemIndex('column');
        if (selectedColumnIdx < 0) {
          return true;
        }
        this.addColumnAtIdx(selectedColumnIdx + 1);
        return true;
      }
      this.addRowAtIdx(selectedRowIdx + 1);
      return true;
    }),


    new Hotkey('alt+backspace', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      const selectedRowIdx = this.state.getFirstSelectedRowIndex();
      if (selectedRowIdx < 0) {
        return true;
      }
      this.deleteRowAtIdx(selectedRowIdx);
      return true;
    }),

    new Hotkey('up', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      if (!this.state.areRowsSelected() || this.state.isFirstRowSelected()) {
        // if no rows selected or the last row is selected, select first row
        const lastRowIndex = this.state.getLastNonHeaderRowIndex();
        this.state.rowSelectionAction(RowSelectionActionType.SELECT_SINGLE, lastRowIndex);
      } else {
        const firstSelectedRowIndex = this.state.beginningSelectedRowIndex();
        this.state.rowSelectionAction(RowSelectionActionType.SELECT_PREVIOUS, firstSelectedRowIndex);
      }
      this.recalculateSelectedCellSum(false, 'select previous');
      this._crd.detectChanges();
      return true;
    }),

    new Hotkey('down', (evt: KeyboardEvent, combo: string) => {
      this.stopEditingText();
      if (!this.state.areRowsSelected() || this.state.isLastRowSelected()) {
        // if no rows selected or the last row is selected, select first row
        const firstRowIndex = this.state.getFirstNonHeaderRowIndex();
        this.state.rowSelectionAction(RowSelectionActionType.SELECT_SINGLE, firstRowIndex);
      } else {
        const lastSelectedRowIndex = this.state.endingSelectedRowIndex();
        this.state.rowSelectionAction(RowSelectionActionType.SELECT_NEXT, lastSelectedRowIndex);
      }
      this.recalculateSelectedCellSum(false, 'select next');
      this._crd.detectChanges();
      return true;
    }),

    new Hotkey(['ctrl+s'], (evt: KeyboardEvent, combo: string) => {
      this.saveAndStay();
      return true;
    }),
  ];

  // Keep track of validation errors that the user has ignored, by the "uniqid" (row, column, off by amount).
  ignoredValidationErrors: Array<string> = [];

  private delay = (function() {
    let timer: any = null;
    return function(callback: any, ms: number) {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(callback, ms);
    };
  })();

  constructor(
    private _hotkeysService: HotkeysService,
    private _crd: ChangeDetectorRef,
    private _popupService: NgxPopupService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _documentFileService: DocumentFileService,
    private _userService: UserService,
    private _alertService: AlertService,
    private _sharedDataService: SharedDataService,
    private _statementDataService: StatementDataService,
    private _launchDarklyService: LaunchDarklyService,
    private _trackingService: TrackingService,
    private _loggingService: LoggingService,
    private _goalSeekSerice: GoalSeekService,
    private _dataFrameService: DataFrameService,
    private _borrowerService: BorrowerService,
    private _spreadingTemplateService: SpreadingTemplateService,
    private _spreadingHistoryService: SpreadingHistoryService,
    private _statementService: StatementService,
    public userGuideService: UserGuideService,
    private commentService: CommentService,
    private digitizationService: DigitizationService
  ) {
    this.logger = this._loggingService.rootLogger.newLogger('UserService');
  }

  ngOnInit() {
    if (this.isBorrowerLockedForRespread()){
      this.showReSpreadInProgressView()
    }
    this.addOrRemoveHotkeys();
    this.userGuideService.add(USER_GUIDES.CATEGORIZING_DATA);
    this.userGuideService.add(USER_GUIDES.ABOUT_FOOTNOTES);
    this.userGuideService.add(USER_GUIDES.TROUBLESHOOTING_SPREAD);
    this.userGuideService.add(USER_GUIDES.MAKING_ADJUSTMENTS);
    this.userGuideService.add(USER_GUIDES.SPREAD_ASSIST);
    this.userGuideService.add(USER_GUIDES.FINCURA_SPREADING_GUIDE);

    // Due to the way angular optimizes, this component will exist as a "singleton"
    // as long as they are clicking between the tabs at the bottom, even though we
    // are reloading data every time. Each load of the screen within the same
    // file needs 90% of the same data (only thing different is the actual statement
    // rows and columns), so to make things snappier we have a cache instance
    // every time you load this screen.
    this.dataCache = new StatementDataCache();
    if (!this._userService.isSpreader()) {
      this._router.navigate(['companies']);
    }
    this._sharedDataService.hideDefaultNavigation();

    this.subsArr$.push(this._route.paramMap.subscribe(params => {
      this.statementUuid = params.get('statement_uuid');
      this.loadData();
    }));

    this.subsArr$.push(this._sharedDataService.appClicks$.subscribe(() => {
      this.hideContextMenu();
    }));

    this._sharedDataService.pageHeaderTitle$.next('Templates');
  }

  isBorrowerLockedForRespread(): boolean {
    const resolverData = this._route.snapshot.data
    return resolverData.companyIsBeingReSpread
  }

  showReSpreadInProgressView(): void {
    this.reSpreadInProgress = true;
    this.loading = false;
  }

  showComment(row: Row, cell: Cell): void {
    this.rowForComment = row;
    this.cellForComment = cell;
    this.commentService.showCommentComponent();
  }

  handleValue(value: string) {
    this.receivedValue = value
  }

  addComment(row: Row, cell: Cell): void {
    this.rowForComment = row;
    this.cellForComment = cell;
    this.cellForComment.sourceComments[0] = {
      id: null,
      uuid: null,
      comment: '',
      reviewQueueItemId: null,
      sourcePageNumber: null,
      sourceBoxUlcX: null,
      sourceBoxUlcY: null,
      sourceBoxUrcX: null,
      sourceBoxUrcY: null,
      sourceBoxLrcX: null,
      sourceBoxLrcY: null,
      sourceBoxLlcX: null,
      sourceBoxLlcY: null,
      statementId: null
    }
    this.commentService.showCommentComponent();
  }

  openCommentModal(row: Row, cell: Cell) {
    this._popupService.open({
      componentType: CommentModalComponent,
      inputs: {
        cellWithComment: cell,
        rowWithCell: row,
        spreadingState: this.state
      },
      outputs: {
        saveComment: () => {
          this.saveAndStay();
        }
      }
    });
  }

  openCommentDeleteModal(cell: Cell) {
    this._popupService.open({
      componentType: CommentDeleteModalComponent,
      cssClass: 'comment-delete-modal',
      inputs: {
        cellWithComment: cell
      },
      outputs: {
        deleteCommentEvent: () => {
          this.saveAndStay();
        }
      }
    });
  }

  addOrRemoveHotkeys() {
    this.hotkeys.push(new Hotkey(['ctrl+z', 'command+z'], (evt: KeyboardEvent, combo: string) => {
      this.undoButtonLoading = true;
      this._crd.detectChanges();
      this.undo();
      return true;
    }));
    this.hotkeys.push(new Hotkey(['ctrl+shift+z', 'command+shift+z'], (evt: KeyboardEvent, combo: string) => {
      this.redoButtonLoading = true;
      this._crd.detectChanges();
      this.redo();
      return true;
    }));
    this.hotkeys.push(new Hotkey(['command+x', 'ctrl+x'], (evt: KeyboardEvent, combo: string) => {
      this.cutSelected();
      return true;
    }));
    this.hotkeys.push(new Hotkey(['command+c', 'ctrl+c'], (evt: KeyboardEvent, combo: string) => {
      this.copySelected();
      return true;
    }));
    this.hotkeys.push(new Hotkey(['command+v', 'ctrl+v'], (evt: KeyboardEvent, combo: string) => {
      this.pasteSelected();
      return true;
    }));
  }

  cutSelected(): void {
    this.stopEditingText();
    this.clearClipboard();

    const selectedRows = this.state.getAllSelectedRows();

    if (selectedRows.length <= 0) {
      const colIndices = this.state.getAllSelectedColumnIndices();
      if (colIndices.length >= 0) {
        colIndices.forEach((this.copySelectedColumn), this);
        this.state.deleteSelectedColumns();
        this._crd.detectChanges();
      }

      this._alertService.success('Copied column(s)');
    } else {
      this.state.clipboard = JSON.parse(JSON.stringify(selectedRows));
      this.state.deleteSelectedRows();
      this._alertService.success('Copied row(s)');
      this._crd.detectChanges();
    }
    this.storeNewState(SPREAD_ACTION_CUT_SELECTED);
  }

  /**
   * Function that copies selected row objects.
   * If no rows are selected than copies selected column indices.
   */
  copySelected(): void {
    this.stopEditingText();
    this.clearClipboard();

    const selectedRows = this.state.getAllSelectedRows();

    if (selectedRows.length <= 0) {
      const colIndices = this.state.getAllSelectedColumnIndices();
      if (colIndices.length >= 0) {
        colIndices.forEach((this.copySelectedColumn), this);
      }

      this._alertService.success('Copied column(s)');
    } else {
      this.state.clipboard = JSON.parse(JSON.stringify(selectedRows));
      this._alertService.success('Copied row(s)');
    }
  }

   /**
   * Function that pastes clipboard contents to selected column or row.
   */
  pasteSelected(): void {
    this.stopEditingText();
    if (!this.state.clipboard || this.state.clipboard.length <= 0) {
      this._alertService.info('No items copied to the clipboard!');
      return;
    }

    if (this.state.clipboard[0].hasOwnProperty('cells')) {
      const selectedRowIdx = this.state.getFirstSelectedItemIndex('row');

      if (selectedRowIdx < 0) {
        this._alertService.info('Select a row before pasting');
        return;
      }

      this.state.clipboard.forEach((row, idx) => {
        const index = selectedRowIdx + idx;
        this.state.replaceRowAtIndex(row, index);
      });

    } else if (this.state.clipboard[0].hasOwnProperty('column')) {
      const selectedColumnIdx = this.state.getFirstSelectedItemIndex('column');

      if (selectedColumnIdx < 0) {
        this._alertService.info('Select a column before pasting');
        return;
      }

      this.state.clipboard.forEach((col, clipboardIndex) => {
        const colIndex = selectedColumnIdx + clipboardIndex;
        this.state.replaceColumnAtIndex(col, colIndex, clipboardIndex, this.state.clipboard);
      });
    }
    this.state.calculate();
    this._crd.detectChanges();
    this.storeNewState(SPREAD_ACTION_PASTE_SELECTED);

  }

  copySelectedColumn(colIdx, index): void {
    this.state.clipboard.push([]);
    this.state.clipboard[index]['column'] = this.state.columns[colIdx];
    this.state.clipboard[index]['rows'] = [];
    this.state.sourceRows.forEach(row => {
      this.state.clipboard[index]['rows'].push(row.cells[colIdx]);
    });
  }

  clearClipboard(): void {
    this.state.clipboard = [];
  }

  addRowAtIdx(idx: number) {
    this.madeAChange();
    this.state.addRowsAtIndex(idx, 1);
    this._crd.detectChanges();
  }

  deleteRowAtIdx(idx: number) {
    this.stopEditingText();
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'Delete row?',
        text: `Are you sure you want to delete this row? ("${this.state.sourceRows[idx].label}")`,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.madeAChange();
            this.state.deleteRowAtIndex(idx);
            this.recalculateSelectedCellSum(true, SPREAD_ACTION_DELETE_ROW);
            this._alertService.success('Row deleted');
            this._crd.detectChanges();
          }
        }
      },
    });
  }

  deleteAllSelectedRows() {
    this.stopEditingText();
    const count = this.state.getAllSelectedRows().length;
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: `Delete ${count} rows?`,
        text: `Are you sure you want to delete these ${count} selected rows?`,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.madeAChange();
            this.state.deleteSelectedRows();
            this._alertService.success('Rows deleted');
            this._crd.detectChanges();
          }
        }
      },
    });
  }

  madeAChange() {
    this.madeChanges = true;
  }

  columnHeaderDropDownChanged() {
    this.madeChanges = true;
    this.storeNewState(SPREAD_ACTION_COLUMN_METADATA_CHANGED);
  }

  addColumnAtIdx(idx: number) {
    this.madeAChange();
    this.stopEditingText();
    this.state.addColumnsAtIndex(idx, 1);
    this._crd.detectChanges();
    this.storeNewState(SPREAD_ACTION_ADD_COLUMN);
  };

  deleteSelectedColumns() {
    const selectedColumns = this.state.getAllSelectedColumns();
    let question = 'Delete column?';
    let text = 'Are you sure you want to delete this column?';
    let msg = 'Column deleted!';

    if (selectedColumns.length > 1) {
      question = `Delete ${selectedColumns.length} columns?`;
      text = `Are you sure you want to delete these ${selectedColumns.length} columns?`;
      msg = 'Columns deleted!';
    }
    this.stopEditingText();
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: question,
        text: text,
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.madeAChange();
            this.state.deleteSelectedColumns();
            this.recalculateSelectedCellSum(true, SPREAD_ACTION_DELETE_COLUMNS);
            this._alertService.success(msg);
            this._crd.detectChanges();
          }
        }
      },
    });
  }

  invertSelectedRows() {
    this.madeAChange();
    this.stopEditingText();
    this.state.invertSelectedRows();
    this.recalculateSelectedCellSum(true, SPREAD_ACTION_INVERT_ROWS);
    this._crd.detectChanges();
  }

  showSourceForCell(evt, row: Row, cellIdx: number) {
    this.stopEditingText();
    const ableToShowSourceBoxOrPage = this.updateSourceCellBox(row, cellIdx);
    if (ableToShowSourceBoxOrPage) {
      this.documentSidebarIsOpen = true;
      this._crd.detectChanges();
    } else {
      this._alertService.warning('Could not identify source cell or source page.', 'Warning', 5);
    }
  }

  updateSourceCellBox(row: Row, cellIdx: number) {
    this.resetDocumentViewSourceBox();
    const pageNumber = row.cells[cellIdx].pageNumber;
    if (row.cells[cellIdx]?.sourceBox && row.cells[cellIdx].sourceBox !== null && this.sourceBoxIsValid(row.cells[cellIdx].sourceBox, pageNumber)) {
      this.viewSourceBox = row.cells[cellIdx].sourceBox;
      this.viewSourcePage = this.viewSourceBox.pageNumber;

      this._crd.detectChanges();
      return true;
    } else if (pageNumber > -1) {
      this.viewSourceBox = null;
      this.viewSourcePage = row.cells[cellIdx].pageNumber;
      this._alertService.warning('The source cell could not be identified but the source page has been found.', 'Warning', 5);
      this._crd.detectChanges();
      return true;
    }
    return false;
  }

  sourceBoxIsValid(sourceBox, pageNumber) {
    return (
      sourceBox?.llc &&
      sourceBox?.lrc &&
      sourceBox?.ulc &&
      sourceBox?.urc &&
      sourceBox?.llc?.x &&
      sourceBox?.lrc?.x &&
      sourceBox?.ulc?.x &&
      sourceBox?.urc?.x &&
      sourceBox?.llc?.y &&
      sourceBox?.lrc?.y &&
      sourceBox?.ulc?.y &&
      sourceBox?.urc?.y &&
      // if the page number exists on the row, confirm that it matches the source cell page number
      (pageNumber === -1 || pageNumber === sourceBox?.pageNumber)
    )
  }

  setShowSourceEnabledInContextMenu() {
    if (!this.isDASStatement && SUPPORTED_VIEW_SOURCE_FILE_TYPES.includes(this.state.documentFile.fileType)) {
      this.showSourceEnabledInContextMenu = true;
    }
  }

  openMergeTablesInterface() {
    const endingSelectedRow = this.state.endingSelectedRow();
    if (endingSelectedRow && endingSelectedRow.lineItemId) {
      this.mergeTableInsertAtLineItemId = endingSelectedRow.lineItemId;
      this.saveThenNavigateToDedicatedDocumentManagerView('../merge-table', endingSelectedRow.lineItemId)
    }
  }

  openManageFootnotesInterface() {
    const endingSelectedRow = this.state.endingSelectedRow();
    if (endingSelectedRow && endingSelectedRow.lineItemId) {
      this.saveThenNavigateToDedicatedDocumentManagerView('../manage-footnotes', endingSelectedRow.lineItemId)
    }
  }

  saveThenNavigateToDedicatedDocumentManagerView(targetPath: string, targetLineItemId: number) {
    // so we don't lose data while saving merged table
    this._popupService.open({
      componentType: SavingPopupComponent,
      cssClass: 'modal-confirmation'
    });
    this.saveBeforeNavigatingAway()
      .then(() => {
        this._popupService.closeAll();
        this._alertService.success('Spread saved!');
        this.loading = true;
        this._crd.detectChanges();
        this._router.navigate([targetPath], {
          relativeTo: this._route,
          queryParams: {
            targetLineItem: targetLineItemId
          }
        }).finally(() => this.loading = false);
      })
      .catch((err) => {
        this._popupService.closeAll();
        this.loading = false;
        this._crd.detectChanges();
        console.log('Error saving data: ', err);
        this._alertService.error("Unable to save in-progress spread. Make sure the current data is valid and save before proceeding.")
      });
  }

  categorizeSelectedRows(asValidation = false) {
    if (!this.canCategorize) {
      return;
    }
    this.madeAChange();
    this.stopEditingText();
    this.getScrollPosition();
    const firstSelectedRow = this.state.getFirstSelectedRow()
    if (!firstSelectedRow || firstSelectedRow.header || firstSelectedRow.itemClass==='supplementalItem') {
      return;
    }

    firstSelectedRow.showMappingBox = true;
    firstSelectedRow.mappingBoxValidation = asValidation;
    this.assignGoalSeekFlag();
    this._crd.detectChanges();
    this._focusOnAutocomplete();
  }

  getScrollPosition() {
    const containerEl = document.getElementById('src-container');
    if (containerEl) {
      this.scrollTop = containerEl.scrollTop;
      this.scrollLeft = containerEl.scrollLeft;
    }
  }

  setScrollPosition() {
    const containerEl = document.getElementById('src-container');
    if (containerEl) {
      containerEl.scrollTop = this.scrollTop;
      containerEl.scrollLeft = this.scrollLeft;
    }
  }

  toggleDocumentExplorer(evt) {
    if (this.reSpreadInProgress){
      return
    }
    if (this.documentSidebarIsOpen) {
      this.closeDocumentExplorer();
    } else {
      this.openDocumentExplorer(evt);
    }
  }
  /**
   * Opens up the document explorer without showing any boxes
   */
  openDocumentExplorer(evt) {
    this.stopEvent(evt);

    // Empty it out
    this.resetDocumentViewSourceBox();
    this.documentSidebarIsOpen = true;
    this._crd.detectChanges();
  }

  closeDocumentExplorer() {
    this.documentSidebarIsOpen = false;
    this._crd.detectChanges();
  }

  uncategorizeSelectedRows() {
    this.madeAChange();
    this.stopEditingText();
    this.state.clearAllCategorizations(this.state.getAllSelectedRows());
    this.assignGoalSeekFlag()
    this._crd.detectChanges();
    this.storeNewState(SPREAD_ACTION_UNCATEGORIZE_ROW);
  }

  removeSuggestionFromRow(evt: Event, row: Row) {
    this.madeAChange();
    this.stopEditingText();
    this.state.clearGoalSeekSuggestion(row);
    this._crd.detectChanges();
  }

  resetUI() {
    this.madeChanges = false;
    this.currentSum = 0.0;
    this.closeDocumentExplorer();
    this.resetDocumentViewSourceBox();
  }

  getDocumentFileId() {
    const s3KeyParts = this.state.documentFile.name.split('/')
    // a change to the s3 key will have the uuid either as the first or second part of the key string
    return this._userService.getBankId() == s3KeyParts[0] ? s3KeyParts[1] : s3KeyParts[0];

  }

  hideSuggestions(row: Row) {
    this.visibleSuggestionsOriginId = null;
  }

  goalSeek(row: Row) {
    row.goalSeekInProgress = true;
    setTimeout(() => {
      this.goalSeekLineItem = row.lineItemId;
      const selectedColumnIdx = this.state.getLastSelectedItemIndex('column');
      const solution = this._goalSeekSerice.solveGoalSeek(this.state.getGoalSeekSourceRows(row, selectedColumnIdx), -1.0 * row.cells[selectedColumnIdx].offBy, selectedColumnIdx);
      if (!solution) {
        this.goalSeekLineItem = -1;
        this._alertService.warning('Our system was unable to find potential solutions. Categorize more items then try again.', 'No Solutions Found');
      } else {
        this.visibleSuggestionsOriginId = this.goalSeekLineItem;
        this.goalSeekLineItem = -1;
        this.state.updateFromGoalSeekSuggestions(solution, this.visibleSuggestionsOriginId);
        this.scrollToVisibleSuggestion();
      }
      row.goalSeekInProgress = false;
      this._crd.detectChanges();
    }, 0)
  }

  scrollToVisibleSuggestion() {
    setTimeout(() => {
      const visibleTooltips = document.querySelectorAll('.goalSeekTooltip');
      if (visibleTooltips.length) {
        const firstVisibleTooltip =   visibleTooltips[0];
        firstVisibleTooltip.scrollIntoView({behavior: "smooth", block: "center"});
      }
    });
  }

  assignGoalSeekFlag() {
    // goal seek for balance sheet is conditional only on the feature flag
    if (this.state.statementType === 'BALANCE_SHEET') {
      this.enableGoalSeek = true;
    } else if (this.state.statementType === 'INCOME_STATEMENT') {
      this.enableGoalSeek = this.state.enableGoalSeekForIncomeStatement();
    } else {
      this.enableGoalSeek = false;
    }
  }

  async loadData(newState: any = null) {
    this.resetUI();
    this.loading = true;
    this._crd.detectChanges();
    // Load the statement by ID from the URL
    this._statementDataService.loadStatementByUuid(this.statementUuid).then((statement: Statement) => {
      this.isDASStatement = statement.statementType == DYNAMIC_ADJUSTMENT_STATEMENT
      if (this.isDASStatement) {
        this.loadStandardDataFrameRefSnapshot(statement)
      }
      this.statementId = statement.id;
      this.fileId = statement.documentFileId;
      return Promise.all([
        this.loadSpreadingState(statement),
        this.loadRelatedStatements(statement),
      ]);
      }).then(() => {
        this.relatedStatements = this.sortStatements(this.relatedStatements, this.state.taxonomy);
        this.loading = false;
        this.assignGoalSeekFlag();
        this._sharedDataService.pageHeaderTitle$.next(this.state.documentFile.companyName.toString());
        this.hotkeys.forEach(hk => {
          this._hotkeysService.add(hk);
        });

        this.setEnableAccessToDoc();

        if (this.state.rows.length === 0) {
          // No template! Cannot categorize
          this.canCategorize = false;
        } else {
          this.canCategorize = true;
        }

        this._dataFrameService.getPeriodsWithData(this.state.documentFile.company).subscribe(data => {
          const tempArray = [];
          data.forEach(element => {
            tempArray.push({
              preparationType: element.preparationType,
              fiscalYearEnd: element.fiscalYearEnd,
              reportingPeriod: element.value,
              currencyFormat: element.currencyFormat,
            });
          });

          this.existingColumns = tempArray;
        });

        this.swapTranslationLabel = this.getSwapTranslationLabel();

        this._crd.detectChanges();

        try {
          this._trackingService.trackHumanInLoop({
            type: 'Start',
            step: 'Spreading',
            documentFileId: this.getDocumentFileId(),
            documentCompanyId: this.state.documentFile.company,
            documentTenantId: this._userService.getBankId()
          });
        } catch (err) {
          this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
        }

      }).catch((err) => {
        if (err.error_type === 'ObjectDoesNotExist') {
          this._alertService.error('Review queue item could not be found. It may have been deleted.');
        } else {
          this._alertService.error('Oops! Something went wrong. (' + err.message + ')');
          this.logger.error('Error when loading spreading data: ' + err.message, {'errorObject': err});
        }
    }).catch((err) => {
      this.logger.error('Error when loading spreading data: ' + err.message, {'errorObject': err});
    });
  }

  loadStandardDataFrameRefSnapshot(statement) {
    const period_key_uuids = statement?.columns.map(col => col?.periodKeyUuid)
    this._statementService.loadSTDFrameRefSnapshot(
      statement.companyId, period_key_uuids, statement.documentFileId, statement.spreadingTemplateId).subscribe(
      res => {
        if (this.state) {
          this.state.stdFrameRefSnapshot = res
          this.state.calculate();
          this._crd.detectChanges();
        } else {
          this.stdFrameRefSnapshot = res;
        }
      })
  }

  getSwapTranslationLabel() {
    let label = '';
    if (this.state.statement.detectedLanguage) {
      if (this.viewInNativeLanguage) {
        label = 'Display in English';
      } else {
        label = `Display in ${LANGUAGES[this.state.statement.detectedLanguage.language]}`;
      }
    }
    return label;
  }

  swapLanguage() {
    this.viewInNativeLanguage = !this.viewInNativeLanguage;
    this.swapTranslationLabel = this.getSwapTranslationLabel();
  }

  loadSpreadingState(statement: Statement) {
    return this._statementDataService.loadState(statement, this.dataCache).then((state: SpreadingState) => {
      this.state = state;
      if (this.dasRefSnapshot) {
        this.state.dasRefSnapshot = this.dasRefSnapshot;
      }
      if (this.stdFrameRefSnapshot) {
        this.state.stdFrameRefSnapshot = this.stdFrameRefSnapshot;
      }
      this.state.calculate();
      this._crd.detectChanges();
      // only initialize if currently empty.
      if (this._spreadingHistoryService.shouldThisBeInitialized()) {
        this._spreadingHistoryService.initializeNewHistory(state);
      }
      if (state.reviewQueueItem && state.reviewQueueItem.embeddedWorkflow) {
        this._sharedDataService.embeddedWorkflow$.next(state.reviewQueueItem.embeddedWorkflow);
      }

      this.selectedTemplate = this.state.spreadingTemplateId;
      this.loadCompanyInfo(statement);
      this.setShowSourceEnabledInContextMenu();
      this.loadOriginalDocumentDataForDownload();
    });
  }

  loadCompanyInfo(statement: Statement) {
    this._borrowerService.getCompanyById(statement.companyId).subscribe((company: Company) => {
      this.isTemplateChooserDisabled = company.defaultSpreadingTemplate !== null;
      this.companyObject = company;
      // once the templateChooserDisabled flag is set, we can load in the template info
      this.loadTemplateInfo();
    });
  }


  loadTemplateInfo() {
    // TODO potential performance improvement: we do not need to load ALL of the templates data here
    this.subsArr$.push(this._spreadingTemplateService.getTemplates('STANDARD', true).subscribe( (data) => {
      const availableTemps = [];

      data.forEach(template => {
        availableTemps.push({'id': template.id, 'text': template.name, 'items': template.items })
        if (this.state.spreadingTemplateId === template.id) {
          this.templateName = template.name;
        }
      });
      this.availableTemplates = availableTemps;
      this._crd.detectChanges();

      // if the default company template has not been chosen and more than one spreading template exists, then show the template selector popup
      if (this.availableTemplates.length > 1 && !this.state.documentFile.initialTemplateSelectionHasBeenMade) {
        this.showSpreadingTemplateSelectionPopup(true);
      }
    }));
  }

  showSpreadingTemplateSelectionPopup(initialTemplateSelection = false) {
    this._popupService.open({
      componentType: SelectSpreadingTemplateModalComponent,
      cssClass: 'modal-lg',
      inputs: {
        availableTemplates: this.availableTemplates,
        defaultTemplateId: this.state.spreadingTemplateId,
        initialTemplateSelection: initialTemplateSelection,
      },
      outputs: {
        selectedTemplateId: (selectedTemplateId: string) => {
          if (selectedTemplateId !== '') {
            this.chooseTemplate(selectedTemplateId);
          } else if (initialTemplateSelection) {
            this.redoManualReview();
          }
        }
      },
    });
  }

  loadRelatedStatements(statement) {
    const fileId = statement.documentFileId;
    return this._statementDataService.loadStatementsForFile(fileId, this.dataCache).then((statements: Array<Statement>) => {
      this.relatedStatements = statements;
      const relatedDASStatement = this.relatedStatements.filter(s => s.statementType === DYNAMIC_ADJUSTMENT_STATEMENT);
      if (relatedDASStatement.length) {
        this.spreadContainsDASStatement = true
        this.loadDASSnapshotData(statement.uuid, fileId)
      }
    });
  }


  loadDASSnapshotData(excludedStatementUuid, documentFileId){
    this._statementService.loadDASStatementSnapshot(excludedStatementUuid, documentFileId).subscribe(result => {
      if (result) {
        if (this.state) {
          this.state.dasRefSnapshot = result;
          this.state.calculate();
          this._crd.detectChanges();
        } else {
          this.dasRefSnapshot = result
        }

      }
    }, error => {
      console.error('Failed to load das statement snapshot for calculation reference')
    })
  }

  setEnableAccessToDoc() {
    this.enableAccessToDoc = this.state.documentFile.fileType.indexOf('PDF_') === 0;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.loading) {
      return;
    }
    this.cellEditing({
      cellIdx: -1,
      row: null,
    });

    this.stopEditingText();
    this.stopInProgressCategorizations()
  }

  stopInProgressCategorizations() {
    this.state.sourceRows.forEach(row => {
      row.showMappingBox = false;
    });
    this._crd.detectChanges();
  }

  stopPropagation(event){
    event.preventDefault();
    event.stopPropagation();
  }

  columnHeaderChanged(newValue: string, column: Column, attributeName: string) {
    this.madeAChange();
    column[attributeName] = newValue;
    this.stopEditingText();
    this.storeNewState(SPREAD_ACTION_COLUMN_METADATA_CHANGED);
    this._crd.detectChanges();
  }

  editColumnHeader(colIdx: number, attributeName: string) {
    this.stopEditingText();
    this.editingColumnHeaderIndex = colIdx;
    this.editingColumnHeader = attributeName;
    this._crd.detectChanges();
  }

  stopEditingText() {
    this.editingRowLabelIndex = -1;
    this.editingColumnHeaderIndex = -1;
    this.hideContextMenu();
  }

  loadOriginalDocumentDataForDownload() {
    this._documentFileService.getDownloadUrlForOriginalDocument(this.state.documentFile.id).subscribe(data => {
      this.originalDocumentFileUrl = data.originalFileUrl;
    });
  }

  downloadFile() {
    const url = this.originalDocumentFileUrl;
    const link = document.createElement('a');
    link.href = url;
    link.download = this.state.documentFile.originalDocumentName;
    link.target = '_blank';
    link.dispatchEvent(new MouseEvent('click'));
  }

  switchToStatement(statement: Statement) {
    this.stopEditingText();
    // If user edits a row label and clicks
    // directly on statement switch handle the save change
    this._crd.detectChanges();

    const redirect = ['statements', statement.uuid, 'edit'];
    if (this.madeChanges) {
      this._popupService.open({
        componentType: ConfirmationPopupComponent,
        cssClass: 'modal-confirmation',
        inputs: {
          question: 'WARNING',
          text: 'You have unsaved changes. Save and continue?',
        },
        outputs: {
          callback: (approved: boolean) => {
            if (approved) {
              this.saveAndStay(redirect);
            }
          }
        },
      });
    } else if (this.isDASStatement) {
      this._popupService.open({
        componentType: ConfirmationPopupComponent,
        cssClass: 'modal-confirmation',
        inputs: {
          question: 'WARNING',
          text: 'Please save to ensure the calculated values in this view are available in other statements.',
          cancelButtonText: '',
        },
        outputs: {
          callback: (approved: boolean) => {
            if (approved) {
              this.saveAndStay(redirect);
            }
          }
        },
      });
    }
    else {
      this._router.navigate(redirect);
    }
  }

  ngAfterViewInit() {
    this._crd.detach();

    setTimeout(this.enableTooltips, 2000);
  }

  enableTooltips() {
    // Bootstrap tooltip initialization
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  ngOnDestroy() {
    this._sharedDataService.showDefaultNavigation();

    this.userGuideService.remove(USER_GUIDES.CATEGORIZING_DATA);
    this.userGuideService.remove(USER_GUIDES.ABOUT_FOOTNOTES);
    this.userGuideService.remove(USER_GUIDES.TROUBLESHOOTING_SPREAD);
    this.userGuideService.remove(USER_GUIDES.MAKING_ADJUSTMENTS);
    this.userGuideService.remove(USER_GUIDES.SPREAD_ASSIST);
    this.userGuideService.remove(USER_GUIDES.FINCURA_SPREADING_GUIDE);

    this.hotkeys.forEach(hk => {
      this._hotkeysService.remove(hk);
    });

    this._spreadingHistoryService.clear(); // so when you leave, we don't keep the history.
  }

  getLabelForAdjustment(row: Row): string {
    return row.label + ' (Adj.)';
  }

  navigateToBorrower(companyUuid: string) {
    this._router.navigate(['/companies/' + companyUuid, 'financials', 'analysis']);
  }

  addAdjustmentRow(rowIdx: number, row: Row) {
    this.madeAChange();
    this.state.addAdjustmentForRow(rowIdx, this.getLabelForAdjustment(row));
    this._crd.detectChanges();
    this.storeNewState(SPREAD_ACTION_ADJUSTMENT_CREATED);
  }

  showError(err: HttpErrorResponse) {
    alert(err);
  }

  setFirstBoxFocus(): void {
    const elements = document.getElementsByClassName('source-row-td');

    if (elements.length > 0) {
      // @ts-ignore
      elements[0].focus();
    }
  }

  doneLoading() {
    this.setFirstBoxFocus();
    this.hotkeys.forEach(hk => {
      this._hotkeysService.add(hk);
    });
    this.loading = false;
    this._crd.detectChanges();
  }

  _focusOnAutocomplete() {
    const itemNameInputClassName = 'autocomplete';
    setTimeout(() => { // shortterm fix to make sure item is there before we find
      const elements = document.getElementsByClassName(itemNameInputClassName);
      if (elements.length > 0) {
        const formulaNameInputBox = elements[0];
        // @ts-ignore
        formulaNameInputBox.focus();
        formulaNameInputBox.addEventListener('keyup', (evt: KeyboardEvent) => {
            evt.preventDefault();
            let delay = 250;
            // If it's keys for navigating the selection we want a faster response than if we're filtering the values.
            if ([27, 38, 40, 13, 9].includes(evt.keyCode)) {
                delay = 10;
            }
            this.delay(() => {
              if (elements.length < 1) {
                return true;
              }
              const domRect = elements[0].getBoundingClientRect();
              const spaceBelow = window.innerHeight - domRect.bottom;
              if (spaceBelow < 200) {
                setTimeout(() => {
                  const autocompleteElements = document.getElementsByClassName('item');
                  if (autocompleteElements.length > 2) {
                    const thirdAutoCompleteElement = autocompleteElements[2];
                    thirdAutoCompleteElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                  }
                }, 0);
              }
              this._crd.detectChanges();
            }, delay);
            return true;
        });

      }
    }, 50);
  }

  rightClickedRow(evt, rowIdx: number, row: Row) {
    this.stopEvent(evt);
    if (this.isDASStatement){
      return
    }
    this.state.deselectAll();
    row.selected = true;
    this.contextMenuMode = 'row';
    this.contextMenuRow = row;
    this.contextMenuObjectIndex = rowIdx;
    this.showContextMenuAtEventLocation(evt);
  }

  rightClickedColumn(evt, columnIdx: number, column: Column) {
    this.stopEvent(evt);

    // If the column is selected, leave it as is. If not, first select only that column
    if (!column.selected) {
      this.columnToggled(columnIdx, {
        'augment': false,
        'shift': false,
        'ctrl': false,
      });
    }
    this.contextMenuMode = 'column';
    this.contextMenuColumn = column;
    this.contextMenuObjectIndex = columnIdx;
    this.showContextMenuAtEventLocation(evt);
  }

  rightClickedCell(evt, row: Row, cell: Cell, cellIdx: number, rowIdx: number) {
    this.stopEvent(evt);
    // do we need to handle ManualInputItem differently?
    // like if (this.isDASStatement && row?.itemClass !== "ManualInputItem") ??
    if (this.isDASStatement) {
      this.contextMenuMode = 'dasCell';
    } else {
      this.contextMenuMode = 'sourceCell';
    }
    this.contextMenuRow = row;
    this.contextMenuCell = cell;
    this.contextMenuObjectIndex = cellIdx;
    this.showContextMenuAtEventLocation(evt);
    this.cellSelected({
      'row': row,
      'cellIdx': cellIdx,
      'rowIdx': rowIdx,
    })
  }

  selectAndCategorize(evt, row: Row, rowIdx: number, validation = false) {
    if (row.itemClass==='supplementalItem') {
      return;
    }
    this.stopEvent(evt);
    if (this.isCancelActionOfInProgressCategorization(row, rowIdx, validation)) {
      row.showMappingBox = false;
      row.selected = false;
      this._crd.detectChanges();
      return
    }
    this.cancelAllInProgressCategorizationsAndDeselectAll();
    this.stopEditingText();
    row.selected = true;

    if (row.categorizedTo) {
      // This will now be "uncategorize"
      this.uncategorizeSelectedRows();
    } else {
      this.categorizeSelectedRows(validation);
    }
  }

  cancelAllInProgressCategorizationsAndDeselectAll() {
    this.state.sourceRows.forEach(row => {
      row.showMappingBox = false;
      row.selected = false;
    })
  }

  isCancelActionOfInProgressCategorization(row: Row, rowIdx: number, validation: boolean){
    // Clicking the 'c' or 'v' icon for a given row twice will cancel the in progress categorization and deselect
    if (row.categorizedTo || !row.showMappingBox){
      return false
    }
    return row.mappingBoxValidation == validation
  }


  showContextMenuAtEventLocation(evt) {
    const headerOffsetFromBottom = this.isDASStatement ? 150 : 300;
    const nonHeaderOffsetFromBottom = this.isDASStatement ? 150 : 400;
    const distFromBottom = evt.view.innerHeight - evt.pageY;
    let top = evt.pageY;

    // if header row cell, smaller context menu so less offset needed if too low
    if (this.contextMenuMode === 'row' && this.contextMenuRow.header && (distFromBottom < headerOffsetFromBottom)) {
      top = evt.pageY - (headerOffsetFromBottom - distFromBottom);
    }

    // if non header row cell, a larger context menu so less offset needed if too low
    if (this.contextMenuMode === 'row' && !this.contextMenuRow.header && (distFromBottom < nonHeaderOffsetFromBottom)) {
      top = evt.pageY - (nonHeaderOffsetFromBottom - distFromBottom);
    }

    this.contextMenuStyle = {
      'display': 'block',
      'top': `${top}px`,
      'left': `${evt.pageX}px`
    };

    this._crd.detectChanges();
  }

  hideContextMenu() {
    this.contextMenuStyle = {
      'display': 'none',
      'top': '0px',
      'left': '0px',
    };
    this.contextMenuMode = '';
    this.contextMenuRow = null;
    this.contextMenuColumn = null;
    this.contextMenuCell = null;
  }

  rowMapped(evt, row: Row) {
    this.itemMapped({
      'sourceRow': row,
      'templateLineItemId': evt.id,
      'isValidation': row.mappingBoxValidation ? true : false,
      'templateLineItemLabel': evt.value,
    });
  }

  itemMapped(c: Categorization) {
    this.state.categorizeAllSelectedRows(c);
    this.resetMappingBoxConfig();
    this.setScrollPosition();
    this.recalculateSelectedCellSum(false);
    this._crd.detectChanges();
    this.storeNewState(SPREAD_ACTION_CATEGORIZE);
  }

  selectRow(evt, rowIdx: number, row: Row) {
    this.rowToggled(rowIdx, {
      'augment': evt.altKey,
      'shift': evt.shiftKey,
      'ctrl': this.controlKey(evt),
      'row': row,
    });
  }

  rowToggled(idx: number, info: ItemClicked) {
    this.stopEditingText();
    if (info.shift) {
      this.state.rowSelectionAction(RowSelectionActionType.INCLUDE_UNTIL, idx)
    } else if (info.ctrl) {
      if (this.state.isRowSelected(idx)) { // want to unselect it
        this.state.rowSelectionAction(RowSelectionActionType.DESELECT_SINGLE, idx);
      } else { // if not selected, you want to select it
        this.state.rowSelectionAction(RowSelectionActionType.INCLUDE_ADDITIONAL, idx);
      }
    } else {
      this.state.rowSelectionAction(RowSelectionActionType.SELECT_SINGLE, idx);
    }
    this.recalculateSelectedCellSum(true, SPREAD_ACTION_TOGGLE_ROW);
    this._crd.detectChanges();
  }

  selectColumn(evt, colIdx: number, column: Column) {
    this.columnToggled(colIdx, {
      'augment': evt.altKey,
      'shift': evt.shiftKey,
      'ctrl': this.controlKey(evt),
    });
  }

  columnToggled(idx, info: ItemClicked) {
    this.stopEditingText();
    if (info.shift) {
      this.state.columnSelectionAction(RowSelectionActionType.INCLUDE_UNTIL, idx)
    } else if (info.ctrl) {
      if (this.state.isColumnSelected(idx)) { // want to unselect it
        this.state.columnSelectionAction(RowSelectionActionType.DESELECT_SINGLE, idx);
      } else { // if not selected, you want to select it
        this.state.columnSelectionAction(RowSelectionActionType.INCLUDE_ADDITIONAL, idx);
      }
    } else {
      this.state.columnSelectionAction(RowSelectionActionType.SELECT_SINGLE, idx);
    }
    this.recalculateSelectedCellSum(true, SPREAD_ACTION_TOGGLE_COLUMN);
    this._crd.detectChanges();
  }

  clearedCategorization(row: Row) {
    this.state.clearCategorization(row);
    this.storeNewState(SPREAD_ACTION_CLEAR_CATEGORIZATION);
    this._crd.detectChanges();
  }

  editCell(row: Row, cellIdx: number) {
    this.cellEditing({
      'row': row,
      'cellIdx': cellIdx,
    });
  }

  editCellAndStopEvent(evt, row: Row, cellIdx: number) {
    this.receivedValue = this.contextMenuCell.rawText
    this.stopEvent(evt);
    this.editCell(row, cellIdx);
  }

  cellEditing(cellEvt: CellEvent) {
    this.stopEditingText();
    this.state.sourceRows.forEach(r => {
      r.cellEditingIndex = -1;
      r.cellSelectedIndex = -1;
    });

    if (cellEvt.row) {
      cellEvt.row.cellSelectedIndex = cellEvt.cellIdx;
      cellEvt.row.cellEditingIndex = cellEvt.cellIdx;
    }

    this.state.calculate();
    this._crd.detectChanges();
  }

  highlightCell(row: Row, cellIdx: number, rowIdx: number) {
    this.stopEditingText();
    this.stopInProgressCategorizations();
    this.cellSelected({
      'row': row,
      'cellIdx': cellIdx,
      'rowIdx': rowIdx,
    });
  }

 handleKeyDown(cell, event: KeyboardEvent) {
  if (event.key === 'Escape') {
    this.justPressedEscape = true

    cell.value = this.receivedValue
    cell.rawText = this.receivedValue

    this.cellEditing({
      cellIdx: -1,
      row: null,
    });

    this.stopEditingText();
    this.state.rowSelectionAction(RowSelectionActionType.DESELECT_ALL);
    this.stopInProgressCategorizations();
    this.recalculateSelectedCellSum(false, 'deselect all');

    this.state.calculate();
    this._crd.detectChanges();
    return true;
  }
}

  cellSelected(cellEvt: CellEvent) {
    this.state.sourceRows.forEach(r => {
      r.cellEditingIndex = -1;
      r.cellSelectedIndex = -1;
    });

    cellEvt.row.cellSelectedIndex = cellEvt.cellIdx;

    // Select the row and column that cell is in
    this.state.rowSelectionAction(RowSelectionActionType.SELECT_SINGLE, cellEvt.rowIdx);
    this.state.columnSelectionAction(RowSelectionActionType.SELECT_SINGLE, cellEvt.cellIdx);

    if (this.documentSidebarIsOpen) {
      this.updateSourceCellBox(cellEvt.row, cellEvt.cellIdx);
    }
    this._crd.detectChanges();
  }

  doneEditingCell(newValue: string, row: Row, cellIdx: number) {
    if (this.justPressedEscape) {
      newValue = this.receivedValue
    }
    this.justPressedEscape = false;
    this.cellDoneEditing({
      'rawText': newValue,
      'row': row,
      'value': this.parseCellValueAsNumber(newValue),
      'cellIdx': cellIdx
    });
  }

  parseCellValueAsNumber(value: string): number {
    let noCommasOrWhitespace = value.replace(/[,\s]+/g, '');
    let multiplier = 1.0;
    if (noCommasOrWhitespace[0] === '(' && noCommasOrWhitespace[noCommasOrWhitespace.length - 1] === ')') {
      multiplier = -1.0;
      noCommasOrWhitespace = noCommasOrWhitespace.substring(1, noCommasOrWhitespace.length - 1);
    }

    if (this.isNumber(noCommasOrWhitespace)) {
      const parsed = parseFloat(noCommasOrWhitespace);
      return parsed * multiplier;
    }

    return NaN;
  }

  isNumber(number: string): boolean {
    const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
    return numberRegex.test(number);
  }

  cellDoneEditing(cellEvt: CellEvent) {
    let didMakeAChange = false;

    if (cellEvt && cellEvt.rawText && (cellEvt.cellIdx || cellEvt.cellIdx === 0) &&
        cellEvt.row && cellEvt.row.cells &&
        cellEvt.rawText === cellEvt.row.cells[cellEvt.cellIdx].rawText) {
          // skip change if we know for sure that the edit
          // operation caused no change in value
          ;
    } else {
      this.madeAChange();
      didMakeAChange = true;
    }
    this.state.sourceRows.forEach(r => {
      r.cellEditingIndex = -1;
    });

    const cell = cellEvt.row.cells[cellEvt.cellIdx];
    // Value is a read-only prop. Raw text is the one we actually use.
    cell.value = cellEvt.value;

    // If it's a number, just set the raw text to that number so we "format and save it"
    if (!isNaN(cell.value)) {
      cell.rawText = cell.value.toString();
    } else {
      cell.rawText = cellEvt.rawText;
    }


    if (isNaN(cell.value)) {
      cell.confidence = 0.0;
    } else {
      cell.confidence = 1.0;
    }

    this.state.calculate();

    if (didMakeAChange) {
      this.recalculateSelectedCellSum(true, SPREAD_ACTION_EDIT_A_CELL);
    }

    this._crd.detectChanges();
    // Update the value asynchronously
    this.saveChangesForCell(cell, cellEvt.row.lineItemId, cellEvt.value);
  }

  recalculateSelectedCellSum(shouldStoreState = true, action = 'no action specified') {
    const currentSum = this.state.getSelectedRowSum() as any;
    if (isNaN(parseFloat(currentSum))) {
      this.currentSum = 0.0;
    } else {
      this.currentSum = currentSum;
    }
    if (shouldStoreState) {
      this.storeNewState(action);
    }
  }

  validationSourceRowIsValid(row: Row) {
    const templateRow = this.state.templateMap.get(row.categorizedTo);
    return templateRow.cells.every(c => !c.offBy);
  }

  saveAndStay(redirect = null): void {
    this.save({complete: false, redirect: redirect, initialTemplateSelectionHasBeenMade: false, reAutonorm: false, allowDeletingInsertedRows: true, refreshPage: false }, false);

  }

  saveAndComplete(): void {
    // Check to see if this statement has projections because this statement might not yet have been saved.
    const currentStatementHasProjections =
      this.state.statement.columns.filter(column => column['scenario'] === SCENARIO_TYPE_PROJECTION).length > 0;

    const companyId = this.state.documentFile.company;
    this.updateCurrencyForCompany();
    this._statementService.otherStatementHasProjections(
      companyId,
      this.state.documentFile.id,
      this.state.statement.id
    ).subscribe((anyStatementBesidesCurrentStatementHasProjections: boolean) => {
      if (currentStatementHasProjections || anyStatementBesidesCurrentStatementHasProjections) {
        this.performSaveWhenProjectionsArePresent();
      } else {
        this.performSaveWhenProjectionsAreNotPresent();
      }
    });
  }

  performSaveWhenProjectionsAreNotPresent() {
    this._borrowerService.getCompanyById(this.state.documentFile.company).subscribe((company: Company) => {
      this.save({
        complete: true,
        redirect: ['companies', company.uuid, 'financials', 'analysis'],
        initialTemplateSelectionHasBeenMade: false,
        reAutonorm: false,
        allowDeletingInsertedRows: true,
        refreshPage: false,
      });
    });
  }

  performSaveWhenProjectionsArePresent() {
    this._borrowerService.getCompanyById(this.state.documentFile.company).subscribe((company: Company) => {
      this.digitizationService
      .get(this.state.documentFile.id, this.state.reviewQueueItem.id)
      .subscribe((digitization: Digitization) => {
        if (!(!!digitization.projectionName) || digitization.projectionName.length === 0) {
          // Prompt the user for the projection name since is not set and at least one scenario is a projection.
          this._popupService.open({
            componentType: PromptForProjectionNameComponent,
            cssClass: 'modal-confirmation',
          }).then((popup: NgxPopupComponent) => {
            popup.addEventListener('close', (data: CustomEvent) => {
              if (data.detail === true) {
                this.save({
                  complete: true,
                  redirect: ['companies', company.uuid, 'financials', 'analysis'],
                  initialTemplateSelectionHasBeenMade: false,
                  reAutonorm: false,
                  allowDeletingInsertedRows: true,
                  refreshPage: false,
                });
              }
            }, {once: true});
          }).catch(error => {
            this._alertService.error(`${error.message}`);
          });
        } else {
          this.save({
            complete: true,
            redirect: ['companies', company.uuid, 'financials', 'analysis'],
            initialTemplateSelectionHasBeenMade: false,
            reAutonorm: false,
            allowDeletingInsertedRows: true,
            refreshPage: false,
          });
        }
      });
    });
  }

  saveBeforeNavigatingAway(): Promise<any> {
    // temp save before merge footnotes starts so we don't lose data
    const isComplete = false;
    const shouldForceIntegrationSync = false;
    return this._statementDataService.save(this.state, isComplete, shouldForceIntegrationSync);
  }


  findSupplementalItem(statement: Statement) {
    return statement.rows.some(row => row.itemClass === 'supplementalItem');
  }


  save(options = {complete: false, redirect: null, initialTemplateSelectionHasBeenMade: false, reAutonorm: false, allowDeletingInsertedRows: false, refreshPage: false }, reload: boolean = false) {
    const complete = options.complete;
    const redirect = options.redirect;
    const initialTemplateSelectionHasBeenMade = options.initialTemplateSelectionHasBeenMade;
    const reAutonorm = options.reAutonorm;
    const allowDeletingInsertedRows = options.allowDeletingInsertedRows;
    const refreshPage = options.refreshPage;

    this.state.spreadingTemplateId = this.selectedTemplate;

    this._popupService.open({
      componentType: SavingPopupComponent,
      cssClass: 'modal-confirmation'
    }).then(() => {
      return this._statementDataService.save(this.state, complete, this.forceIntegrationSync, initialTemplateSelectionHasBeenMade, reAutonorm, allowDeletingInsertedRows);
    }).then((statement) => {


      if(this.findSupplementalItem(statement) && !this.findSupplementalItem(this.state.statement)){
        //synchronous method
        this.state.setStatement(statement);
        this._crd.detectChanges();
      }


      this._popupService.closeAll();
      this.madeChanges = false;
      if (!reAutonorm) {
        this._alertService.success('Spread saved!');
      } else {
        this._alertService.success('Successfully re-spread document!');
      }
      if (complete) {
        this.alreadyCompleted = true;
        this._crd.detectChanges();
      }

      try {
        this._trackingService.trackHumanInLoop({
          type: 'End',
          step: 'Spreading',
          documentFileId: this.getDocumentFileId(),
          documentCompanyId: this.state.documentFile.company,
          documentTenantId: this._userService.getBankId()
        });
      } catch (err) {
        this.logger.error('Error in tracking human-in-loop event: ' + err.message, {'errorObject': err});
      }

      if (redirect) {
        if (complete) {
          const flow = this._sharedDataService.embeddedWorkflow$.getValue();
          if (flow) {
            ACCEPTABLE_URLS.forEach((acceptable_url) => {
              try {
                window.parent.postMessage({
                  'eventName': 'workflowComplete',
                  'workflow': {},
                }, acceptable_url);
              } catch (ex) {/* pass */}
            });

            if (!!flow.redirectUrl) {
              window.parent.location = flow.redirectUrl;
              return;
            } else {
              return;
            }
          }
        }

        this._router.navigate(redirect);
        return;
      }

      if (reload) {
        this.loadData();
      }

      if (refreshPage) {
        window.location.reload();
      }
    }).catch(error => {
      console.error(error);
      this._popupService.closeAll();
      if (error && error?.message && String(error.message) !== 'undefined') {
          const title = error?.error_title ? error.error_title : UNKNOWN_ERROR_TOAST_TITLE;
          this._alertService.error(error.message, title)
      } else {
        this._alertService.error(UNKNOWN_ERROR_TOAST_BODY, UNKNOWN_ERROR_TOAST_TITLE);
      }
    });
  }

  cellShouldAlignRight(cell: Cell): boolean {
    return !isNaN(cell.value);
  }

  redoManualReview() {
    this._documentFileService.getReviewQueueItemForFileId(this.state.documentFile.id).subscribe((data) => {
      const reviewQueueItemId = data.response.objects[0].reviewQueueItem;
      this._router.navigate(['review', reviewQueueItemId, 'manual_review']);
    },
    error => {
        this._alertService.error('Unable to get Review Queue Item');
    });
  }

  toggleStepsToSuccess() {
    if (this._sharedDataService.shouldShowManualReviewSidebar === true) {
      this._sharedDataService.shouldShowManualReviewSidebar = false;
    } else {
      this._sharedDataService.shouldShowManualReviewSidebar = true;
    }
  }

  getAutocompleteOptionsForRow(row: Row): Array<AutocompleteOption> {
    if (row.derivedFromTemplate) {
      return this.findItemInTemplateMatchingLabel(row.label)
    } else if (row.mappingBoxValidation) {
      return this.state.autocompleteOptions.concat(this.state.rollupOptions);
    }
    return this.state.autocompleteOptions;
  }

  findItemInTemplateMatchingLabel(label) {
    let options: Array<AutocompleteOption> = [];
    for(const [, lineItem] of this.state.templateMap) {
      if (lineItem.label === label) {
        options.push({
          'id': lineItem.lineItemId,
          'value': lineItem.label,
        })
      }
    }
    return options
}

  rowLabelChanged(newValue: string, row: Row) {
    if (row.label !== newValue) {
      row.label = newValue;
      this.madeAChange();
      this.storeNewState(SPREAD_ACTION_ROW_LABEL_CHANGED);
    }
    this.stopEditingText();
    this._crd.detectChanges();
  }


  editRowLabel(rowIdx: number) {
    this.editingRowLabelIndex = rowIdx;
    this._crd.detectChanges();
  }

  saveChangesForCell(cell: Cell, lineItemId: number, newValue: number) {
    this._statementDataService.changeCellValue(cell, lineItemId, newValue).then(() => {
      return;
    }).catch(err => {
      console.error(err);
    });
  }

  /**
   * Converts a column index to its excel equivalent in alphabet notation.
   *
   * E.g.
   *
   * 0 -> A
   * 1 -> B
   * ...
   * 25 -> Z
   * 26 -> AA
   * ...
   * etc
   *
   * @param idx
   */
  headerForColumnIndex(idx: number): string {
    let num = idx + 1;

    const lettersInAlphabet = 26;
    // Get us to the first letter after all the other stuff. This only works because the char codes
    // for letters are in alphabetic order and start at 65.
    const charCodeOffset = 65;
    let ret = '';
    for (let a = 1, b = lettersInAlphabet; (num -= a) >= 0; a = b, b *= lettersInAlphabet) {
      const charCode = ((num % b) / a) + charCodeOffset;
      ret = String.fromCharCode(charCode) + ret;
    }
    return ret;
  }

  sortStatements(statements: Array<Statement>, taxonomy: Array<TemplateItem>) {
    let sortOrder = {};
    taxonomy.forEach(templateItem => {
      if (templateItem.isDynamicAdjustmentStatement) {
        if (!sortOrder.hasOwnProperty(DYNAMIC_ADJUSTMENT_STATEMENT)) {
          sortOrder[DYNAMIC_ADJUSTMENT_STATEMENT] = {}
        }
        sortOrder[DYNAMIC_ADJUSTMENT_STATEMENT][templateItem.label] = taxonomy.indexOf(templateItem) + 1;
      }
      else if (templateItem['lineItemId'] in TAXONOMY_ID_STATEMENT_TYPE_LOOKUP) {
        sortOrder[TAXONOMY_ID_STATEMENT_TYPE_LOOKUP[templateItem['lineItemId']]] = taxonomy.indexOf(templateItem) + 1;
      }
    });

    return statements.sort((a: Statement, b: Statement): number => {
      if (!sortOrder.hasOwnProperty(a.statementType) || !sortOrder.hasOwnProperty(b.statementType)) {
        return 0;
      }

      const aOrder = a.statementType === DYNAMIC_ADJUSTMENT_STATEMENT ? sortOrder[a.statementType][a.uniqueDasName] : sortOrder[a.statementType];
      const bOrder =  b.statementType === DYNAMIC_ADJUSTMENT_STATEMENT ? sortOrder[b.statementType][b.uniqueDasName] : sortOrder[b.statementType];

      if (aOrder > bOrder) {
        return 1;
      } else if (aOrder < bOrder) {
        return -1;
      }
      return 0;
    });
  }

  willColumnOverwrite(column: any): boolean {

    if (column.scenario === SCENARIO_TYPE_PROJECTION) {
      // We do not overwrite, delete, etc. projections.
      return false;
    }

    let doesColumnExist = false;
    this.existingColumns.forEach(element => {
      if (column.preparationType === element.preparationType &&
        column.fiscalYearEnd.includes(element.fiscalYearEnd) &&
        column.reportingPeriod === element.reportingPeriod
      ) {
        doesColumnExist = true;
      }
    });
    return doesColumnExist;
  }

  chooseTemplate(templateIdChosen: string): void {
    const numericTemplateId = parseInt(templateIdChosen, 10);
    this.selectedTemplate = numericTemplateId;

    if (numericTemplateId) {
      this.hasTemplate = true;
      this._crd.detectChanges();
      this.save({complete: false, redirect: null, initialTemplateSelectionHasBeenMade: true, reAutonorm: false, allowDeletingInsertedRows: false, refreshPage: true }, true);
    }
    return;
  }

  storeNewState(action = ''): void {
    const newStateAdded = this._spreadingHistoryService.addNewState(Object.assign({}, this.state), action);
    if (newStateAdded) {
      this.noMoreUndos = false;
    }
  }

  undo(): void {
    this.undoButtonLoading = true;
    setTimeout(() => {
      this._crd.detectChanges();

      const spreadingHistoryData = this._spreadingHistoryService.undo();
      if (spreadingHistoryData) {
        const newStateValues = spreadingHistoryData['newStateValues'];
        this.noMoreUndos = spreadingHistoryData['noMoreUndos'];
        this.noMoreRedos = spreadingHistoryData['noMoreRedos'];
        this.state.sourceRows = newStateValues.sourceRows;
        this.state.statement.rows = newStateValues.sourceRows; // this.state.statement is what ultimately
        // gets saved. this.statement.rows is actually a representation of sourceRows, which made tracing
        // somewhat difficult and confusing
        this.state.columns = newStateValues.columns;
        this.state.rows = newStateValues.rows;
        this.state.referenceRows = newStateValues.referenceRows;
      }
      this.undoButtonLoading = false
      this._crd.detectChanges();
    }, 300);
  }

  redo(): void {
    this.redoButtonLoading = true;
    setTimeout(() => {
      const spreadingHistoryData = this._spreadingHistoryService.redo();
      if (spreadingHistoryData) {
        const newStateValues = spreadingHistoryData['newStateValues'];
        this.noMoreUndos = spreadingHistoryData['noMoreUndos'];
        this.noMoreRedos = spreadingHistoryData['noMoreRedos'];
        this.state.sourceRows = newStateValues.sourceRows;
        this.state.statement.rows = newStateValues.sourceRows; // this.state.statement is what ultimately
        // gets saved. this.statement.rows is actually a representation of sourceRows, which made tracing
        // somewhat difficult and confusing
        this.state.columns = newStateValues.columns;
        this.state.rows = newStateValues.rows;
        this.state.referenceRows = newStateValues.referenceRows;
      }
      this.redoButtonLoading = false;
      this._crd.detectChanges();
    }, 300);
  }

  hideTemplate() {
    document.getElementById('template').style.display = 'none';
    document.getElementById('template-spacer').style.display = 'none';
    this.shouldHideTemplate = true;
  }

  toggleTemplateView(): void {
    if (this.reSpreadInProgress) {
      return
    }
    const template = document.getElementById('template');
    const templateContainer = document.getElementById('template-spacer');

    if (!template || !templateContainer) {
      return
    }

    this.shouldHideTemplate = !this.shouldHideTemplate

    if (this.shouldHideTemplate) {
      template.style.display = 'none';
      templateContainer.style.display = 'none';
    } else {
      template.style.display = 'block';
      templateContainer.style.display = 'block';
    }
  }

  updateCurrencyForCompany() {
    this._borrowerService.setSetting('currency', this.state.documentFile.currency, this.companyObject);
  }

  reAutonormDocument() {
    if (this.reSpreadInProgress) {
      return
    }
    this._popupService.open({
      componentType: ConfirmationPopupComponent,
      cssClass: 'modal-confirmation',
      inputs: {
        question: 'RE-SPREAD',
        text: 'This will re-run the autonormalization algorithms and reset all categorizations for this document, removing any manual adjustments you have made to the spread. Are you sure you want to re-spread this document?',
        confirmButtonText: 'Yes'
      },
      outputs: {
        callback: (approved: boolean) => {
          if (approved) {
            this.save({complete: false, redirect: null, initialTemplateSelectionHasBeenMade: false, reAutonorm: true, allowDeletingInsertedRows: false, refreshPage: false}, true);
          }
        }
      },
    });
  }

  private stopEvent(evt = null) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  /**
   * Turns off the flag for showing the mapping box and other related
   * attributes on all source rows
   */
  private resetMappingBoxConfig() {
    this.state.sourceRows.forEach(r => {
      r.showMappingBox = false;
      r.mappingBoxValidation = false;
    });
  }

  private controlKey(evt) {
    if (IS_MAC) {
      return evt.metaKey; // command/apple key
    } else { // assume windows
      return evt.ctrlKey; // use ctrl key
    }
  }

  private resetDocumentViewSourceBox() {
    this.viewSourceBox = null;
    this.viewSourcePage = null
  }


  toggleIncludeItem(row) {
    this.state.calculate();
    this.madeAChange();
    this._crd.detectChanges();
  }

  weightInputChange(row){
    try {
      row.weightDecimal = +((parseFloat(row.weightPercentage) / 100).toFixed(2));
      this.madeAChange();
    } catch (err){
      this.logger.error('Failed to convert weight percentage to decimal: ' + err?.message, {'errorObject': err});
    }
  }

  isCellEditingDisabled(row) {
    if (!this.isDASStatement){
      return false
    }
    return ['AdjustedStandardItem', 'NonAdjustableStandardItem'].includes(row?.itemClass) ||
      ['asReported', 'customCalculation'].includes(row?.dynamicAdjustmentStatementItemType)
  }

  protected readonly DYNAMIC_ADJUSTMENT_STATEMENT = DYNAMIC_ADJUSTMENT_STATEMENT;
}
